<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Sync numbers</div>
      </portal>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <TooltipAction bottom message="Sync numbers">
        <template #activator>
          <SyncIconButton
            class="mr-5 mr-md-11 mb-4 mb-sm-15"
            :disabled="loading || !selected.length"
            @click="openDialogSyncNumbers"
          />
        </template>
      </TooltipAction>
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
    </div>
    
    <div class="number-container">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        item-key="number"
        :loading="loading"
        :loader-height="2"
        :options="options"
        disable-sort
        show-select
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #header.data-table-select="{ on, props }">
          <PrimaryCheckbox
            v-bind="props"
            v-on="on"
            hide-details
          />
        </template>
        
        <template #item.data-table-select="{ select, isSelected }">
          <PrimaryCheckbox
            v-model="isSelected"
            @change="select"
            hide-details
          />
        </template>
        
        <template #item.number="{ item }">
          <span :class="{ 'yellowLight--text': item.sync }">+{{ item.number }}</span>
        </template>
  
        <template #item.sync="{ item }">
          <TooltipAction bottom message="Sync number" v-if="!item.sync">
            <template #activator>
              <v-icon @click="openDialogSyncNumber(item)">$syncTableIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>
      
      <v-pagination
        class="paginationBody text-center pt-6"
        color="paginationBg"
        v-if="dataPagination.lastPage > 1"
        :value="dataPagination.page"
        :length="dataPagination.lastPage"
        :page="dataPagination.page"
        total-visible="8"
        :disabled="loading"
        prev-icon="$prevIcon"
        next-icon="$nextIcon"
        @input="changePage"
      />
    </div>
  
    <DialogConfirm
      v-model="confirmDialog"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="loadingAction"
      @actionSuccess="syncNumbers"
    />
  
  </v-container>
</template>

<script>
import RefreshButton from '@/components/buttons/RefreshButton'
import SyncIconButton from '@/components/buttons/SyncIconButton'
import TooltipAction from '@/components/tooltip/TooltipAction'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox'
import SwitchSm from '@/components/controls/SwitchSm'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { getTotalPages } from '@/helper/app-helper'
import { getCarrierNumbers, syncNumbers } from '@/api/sync-number-routes'

export default {
  name: 'SyncNumbers',
  components: { RefreshButton, SyncIconButton, TooltipAction, SearchField, SelectOnPage, PrimaryCheckbox, SwitchSm, DialogConfirm },
  data: () => ({
    loading: false,
    loadingAction: false,
    selected: [],
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headers: [
      { text: 'Number', value: 'number' },
      { text: 'Sync', value: 'sync' },
    ],
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    selectedItems: [],
    serverSorting: null,
    search: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  mounted() {
    this.loadCarrierNumbers()
  },
  methods: {
    async loadCarrierNumbers() {
      this.selected = []
      this.loading = true
      
      const { success, payload, message } = await getCarrierNumbers({
        page: this.dataPagination.page,
        search: this.search,
      })
      
      if (success) {
        this.items = payload.numbers
        this.dataPagination.lastPage = getTotalPages(payload.meta.total, payload.meta.limit)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    openDialogSyncNumber(item) {
      this.selectedItems = [item.number]
      this.dialogTitle = 'Sync number'
      this.dialogSubTitle = `+${item.number}`
      this.dialogMessage = 'Are you sure you want to sync this number?'
      this.confirmDialog = true
    },
    openDialogSyncNumbers() {
      this.selectedItems = this.selected.map(item => item.number)
      this.dialogTitle = 'Sync numbers'
      this.dialogSubTitle = null
      this.dialogMessage = 'Are you sure you want to sync selected numbers?'
      this.confirmDialog = true
    },
    async syncNumbers() {
      this.loadingAction = true

      const { success, payload, message } = await syncNumbers({
        numbers: this.selectedItems
      })

      if (success) {
        this.updateNumbers(payload)
        this.confirmDialog = false
        this.selected = []
        this.selectedItems = []
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    updateNumbers(numbers) {
      numbers.forEach(number => {
        const index = this.items.findIndex(item => item.number === number.number)
        
        if (index + 1) {
          this.items[index].sync = number.sync
        }
      })
      this.items = this.items.slice()
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadCarrierNumbers()
      }
      this.$vuetify.goTo(0)
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadCarrierNumbers()
    },
  },
}
</script>
